
body {
  margin: 0;
  height: 100%;
  
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/

  /*font-family: 'Alatsi', sans-serif;*/
  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Added by mayan to hide video elements that jump into screen  */
[id^=middleView-] {
  visibility: hidden;
  pointer-events: 'none';
}

@keyframes gradiantAnimcation {
  0% {
      stop-color: #47b259;
      stroke: #47b259;
      color: #47b259;
      background-color: #47b259;
  }
  99.9999% {
      stroke: #47b259;
      stop-color: #fbb500;
      color: #fbb500;
      background-color: #fbb500;
  }
  100% {
      stop-color: #FFFFFF;
      color: #FFFFFF;
      background-color: #FFFFFF;
  }
}